/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import _ from 'lodash';
const MAX_COUNT = 1;
const isWhitespaceString = (str) => {
    if (_.isUndefined(str)) {
        return false;
    } else {
        return !str.replace(/\s/g, '').length
    }
}
const App = ({ topics, topicName, handleTopic }) => {
    const [value, setValue] = useState(_.isEmpty(topicName) ? []: [{
        label: topicName,
        value: topicName
    }]);
    const [isOpen, setIsOpen] = useState(false);
    const suffix = <DownOutlined />
    const listA = _.map(topics, item => {
        return {
            label: item.name,
            value: item.name
        }
    });
    const handleChange = (ele) => {
        if (_.isUndefined(ele[0])) {
            setValue(ele);
            setIsOpen(true);
        } else if (!isWhitespaceString(ele[0])) {
            setValue(ele);
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        handleTopic(ele[0]);
    }
    return (
        <Select
            mode='tags'
            open={isOpen}
            dropdownStyle={{ zIndex: 9999 }}
            maxCount={MAX_COUNT}
            value={value}
            style={{ width: '100%' }}
            onFocus={() => setIsOpen(true)}
            onChange={handleChange}
            onBlur={() => setIsOpen(false)}
            suffixIcon={suffix}
            placeholder='Subject'
            options={listA}
        />
    );
};
export default App;